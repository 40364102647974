import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { deleteTemplate } from './apiFunctions';

const actionsTemplateArray = (
  {
    setEditMode,
    setSelectedTemplate,
    setDisplayForm,
    displayForm,
    setTemplates,
    templates,
  }
) => [
  {
    icon: () => <EditIcon/>,
    tooltip: 'Editer',
    onClick: (event, rowData) => {
      setEditMode(true);
      setSelectedTemplate(rowData);
      setDisplayForm(!displayForm);
    },
  },
  {
    icon: () => <DeleteIcon />,
    tooltip: 'Supprimer',
    onClick: (event, rowData) => {
      deleteTemplate(rowData, setTemplates, templates)
    },
  },
  {
    icon: () => <AddCircleIcon />,
    tooltip: 'Ajouter',
    isFreeAction: true,
    onClick: () => {
      setEditMode(false);
      setSelectedTemplate({})
      setTemplates(templates);
      setDisplayForm(!displayForm)
    },
    iconProps: {
      style: {
        color: 'primary',
        fontSize: 'large',
      }
    }
  }
]


export default actionsTemplateArray
