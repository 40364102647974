/**
 * Compare function based on language-sensitive string comparison
 * for sort usecase
 * @param {*} a value A to compare
 * @param {*} b value B to compare
 * @returns {number}
 * @description You can provide this to the sort method
 * @example
 * array.sort(sortedValues)
 * // or
 * array.sort((a,b) => sortedValues(a,b))
 */
export function sortedValues(a, b) {
  return new Intl.Collator('fr',
    { sensitivity: 'base', numeric: true }).compare(a, b)
}

/**
 * @param {Record<string, any[]>} groupedValue
 * @param {string} key
 * @returns {Record<string, any[]>}
 * @example
 * const groupedData = {
 *  page3: [{ label: "Question Z", ref: "1" }],
 *  page1: [{ label: "Question A", ref: "2" }],
 *  page2: [{ label: "Question M", ref: "3" }]
 * };
 *
 * const sortedGroupedData = sortedGroupedValueByKey(groupedData, "label");
 * console.log(sortedGroupedData);
 * //{
 * // page1: [{ label: "Question A", ref: "2" }],
 * // page2: [{ label: "Question M", ref: "3" }],
 * // page3: [{ label: "Question Z", ref: "1" }]
 * //}
 */
export function sortedGroupedValueByKey(groupedValue, key) {
  return Object.keys(groupedValue)
    .sort((a, b) => {
      const positionA = groupedValue[a].at(0)?.[key] || 0;
      const positionB = groupedValue[b].at(0)?.[key] || 0;
      return positionA - positionB
    })
    .reduce((acc, key) => {
      acc[key] = groupedValue[key];
      return acc;
    }, {});
}
