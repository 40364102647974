import React, { memo, useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ClientHelps from './ClientHelps';

/**
 * @param {{
 * sectionGrouped: Record<string | undefined, QuestionMetier[]>
 * } & import('react').PropsWithChildren} props
*/
function SectionPrensenter({ sectionGrouped, ...props }) {
  const sections = useMemo(
    () => Object.keys(sectionGrouped).filter(e => e !== 'undefined')
    , [sectionGrouped]
  );

  const getElementBySection = useCallback(section => ({
    ...props.element,
    questions: sectionGrouped[section]
  }), [sectionGrouped, props.element]);

  if (sections.length === 0) {
    return <ClientHelps showDetails {...props} />;
  }
  return (
    <>
      {sections.map((section) => (
        <Accordion
          key={`bloc-${section}`}
          defaultExpanded
          slotProps={{ transition: { unmountOnExit: true } }}
          sx={{ mt: 4 }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >
              {section}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ClientHelps {...props} element={getElementBySection(section)} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default memo(SectionPrensenter);
