import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import CustomMaterialTable from '../../CustomMaterialTable';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { TP_SMS } from '@lba-dev/package.local-globals/templatesType'
import CustomTextField from '../../CustomInputs/CustomTextField';
import actionsTemplateArray from './actionsTemplateArray';
import columns from './columns';
import {
  handleRowClick,
  returnToTemplates,
  handleTitleChange,
  handleTextChange,
  handleTemplate
} from './functionsTemplate';

const styles = {
  TextInput: {
    overflow: 'auto',
    maxHeight: 220,
    minHeight: 200,
    height: 200,
  }
};

const setTemplates = setDialogContentProp('templates');
const setSelectedTemplate = setDialogContentProp('selectedTemplate');
const setDisplayForm = setDialogContentProp('displayForm');
const setEditMode = setDialogContentProp('editMode');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      templates = [],
      selectedTemplate = {},
      displayForm = false,
      editMode = false,
    },
  },
}) => ({
  templates,
  selectedTemplate,
  displayForm,
  editMode,
});


const mapDispatchToProps = {
  setTemplates,
  setSelectedTemplate,
  setDisplayForm,
  setEditMode,
};

const DialogTemplates = ({
  templates,
  setTemplates,
  setSelectedTemplate,
  selectedTemplate,
  editMode,
  setEditMode,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayForm, setDisplayForm] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    title: '',
    text: '',
    type: TP_SMS,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          spacing={4}
          py={2}
        >
          <Grid item>
            <Typography variant="h5">
              {editMode ? 'Modifier le template' : 'Templates'}
            </Typography>
          </Grid>
        </Grid>
        {!displayForm && (
          <Grid>
            <CustomMaterialTable
              title="SMS"
              columns={columns}
              data={templates}
              onRowClick={(e, rowData) =>
                handleRowClick(
                  e,
                  rowData,
                  setSelectedTemplate,
                  selectedTemplate
                )}
              options={{
                search: false,
                paging: true,
                pageSize: rowsPerPage,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 25, 50, 100],
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                  backgroundColor: selectedTemplate._id
                  === rowData._id ?
                    '#71BFFF' : '#FFF',
                  color: selectedTemplate._id === rowData._id ?
                    '#FFF' : '#000',
                  transition: 'all 0.3s ease',
                }),
                headerStyle: {
                  backgroundColor: '#f5f5f5',
                },
                draggable: false,
              }}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              actions={
                actionsTemplateArray({
                  setEditMode,
                  setSelectedTemplate,
                  setDisplayForm,
                  displayForm,
                  setTemplates,
                  templates
                }
                )}
            />
          </Grid>
        )}
        {displayForm && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <CustomTextField
                label="Titre"
                variant="outlined"
                onChange={e => {handleTitleChange({
                  e,
                  editMode,
                  selectedTemplate,
                  setSelectedTemplate,
                  newTemplate,
                  setNewTemplate
                })}}
                value={editMode ? selectedTemplate.title : newTemplate.title}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Contenu"
                variant="outlined"
                multiline
                rows={4}
                InputProps={{
                  style: styles.TextInput
                }}
                fullWidth
                onChange={e => {handleTextChange({
                  e,
                  editMode,
                  selectedTemplate,
                  setSelectedTemplate,
                  newTemplate,
                  setNewTemplate
                })}}
                value={editMode ? selectedTemplate.text : newTemplate.text}
              />
            </Grid>
            <Grid container item xs={10} spacing={2}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleTemplate(
                    {
                      editMode,
                      selectedTemplate,
                      newTemplate,
                      setTemplates,
                      setDisplayForm,
                      templates,
                      setNewTemplate,
                      setEditMode,
                    }
                  )}
                >
                  {editMode ? 'Enregistrer' : 'Ajouter'}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  onClick={() => returnToTemplates({
                    setEditMode,
                    setSelectedTemplate,
                    setTemplates,
                    templates,
                    setDisplayForm,
                    displayForm
                  })}
                  variant="contained"
                  color={displayForm && 'secondary'}
                >
                  {displayForm && 'Retour'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogTemplates);
