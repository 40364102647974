import React from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
import Money from '../../../utils/Money';
import BuildIcon from '@mui/icons-material/Build';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import { Skeleton, Typography } from '@mui/material';
const styles = ({ palette }) => ({
  gridContainer: {
    padding: 0
  },
  mainBox: {
    padding: 12,
    borderRadius: 5,
    backgroundColor: palette.primary.main,
  },
  textColor: {
    color: palette.common.white,
  },
  childBackground: {
    backgroundColor: palette.common.white,
    borderRadius: 5,
  },
  subChildBackground: {
    backgroundColor: palette.common.white,
    margin: 4
  },
  listIcons: {
    color: palette.grey[500],
    verticalAlign: 'middle',
    fontSize: 18,
    margin: '1%',
  },
  listIconsCheck: {
    color: palette.success.main,
    verticalAlign: 'middle',
    fontSize: 18,
    margin: '1%',
  },
  normalText: {
    fontWeight: 400,
  },
  priceTitle: {
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
  },
  priceText: {
    textAlign: 'right',
    paddingRight: 18,
    whiteSpace: 'nowrap'
  },
  esmimateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  estimateTitle: {
    textAlign: 'center',
    fontWeight: 300
  },
  estimatedBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  estimatedValue: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: palette.success.main
  }
});

export default withStyles(({ classes, element, dp, loadingDp }) => (
  <Grid item xs={4} className={classes.gridContainer}>
    <Box className={classes.mainBox}>
      <Box p={1}>
        <Typography
          variant="h4"
          className={classes.textColor}
          children={element.description}
        />
      </Box>
      <Divider className={classes.textColor} />
      <Box p={1} mb={3}>
        <Grid container spacing={2} className={classes.childBackground}>
          <Grid item xs={6} className={classes.priceTitle}>
            <Typography
              color="primary"
              variant="subtitle2"
              children={'Le tarif estimatif'}
              className={classes.normalText}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              color="primary"
              style={{ fontWeight: 'bold' }}
              className={classes.priceText}
              children={`${element.minPrice && element.maxPrice ?
                `entre ${Money.toString(element.minPrice).slice(0, -3)
                } € et ${Money.toString(element.maxPrice).slice(0, -3)} €`
                : `${element.obligDevis ? 'à partir de' : 'Prix fixe'
                } ${Money.toString(
                  element.maxPrice || element.minPrice,
                  true
                )}`}`
              }
              align="center"
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={1}>
        <Grid container spacing={1} className={classes.childBackground}>
          <Grid item xs={12} className={classes.subChildBackground}>
            <Typography
              variant={'h6'}
              className={classes.normalText}
              children="Inclus dans la prestation"
            />
          </Grid>
          <Grid item xs={12} className={classes.subChildBackground}>
            {element.prestations && element.prestations.length ? (
              element.prestations.map((e, i) => (
                <Typography key={i}>
                  <CheckIcon className={classes.listIconsCheck} /> {e.name}
                </Typography>
              ))
            ) : (
              <Typography
                component="p"
                variant="caption"
                children="Aucune prestation"
              />
            )}
          </Grid>
          {element.materials.length
            ? <Grid item xs={12} className={classes.subChildBackground}>
              <Typography
                variant={'h6'}
                className={classes.normalText}
                children="À vendre sur place"
              />
            </Grid> : ''}
          <Grid item xs={12} className={classes.subChildBackground}>
            <Grid container>
              {Array.isArray(element.materials) ? (
                element.materials.map((e, i) => <React.Fragment key={i}>
                  <Grid item xs={10} className={classes.priceTitle}>
                    <BuildIcon className={classes.listIcons} />
                    <Typography>{'  '}{e.name}</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 0 }}>
                    <Typography
                      align="center"
                      color="primary"
                      className={classes.priceText}
                      children={`${Money.toString(e.price).slice(0, -3)} €`}
                    />
                  </Grid>
                </React.Fragment>)
              ) : (
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    variant="caption"
                    children="Aucun matériaux"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
    {
      loadingDp && !dp &&
      <Typography variant='h4' sx={{ textAlign: 'center', mt: 20 }}>
        Estimation de votre besoin en cours...
      </Typography>
    }
    {
      dp && <Grid
        container
        rowSpacing={5}
        className={classes.esmimateContainer}
      >
        <Grid item xs={12}>
          <Typography
            className={classes.estimateTitle}
            variant='h4'
          >
            Votre besoin estimé
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.estimatedBox}>
          <Typography variant='caption'>
            Coefficient de déperditions G
          </Typography>
          {
            loadingDp ? <Skeleton width={100} height={50} /> :
              <Typography
                variant='h5'
                className={classes.estimatedValue}
              >
                {dp?.G ?? 'N/A'} <br/>W/m3/K
              </Typography>
          }
        </Grid>
        <Divider orientation='vertical' flexItem />
        <Grid item xs={5} className={classes.estimatedBox}>
          <Typography variant='caption'>
            Déperditions
          </Typography>
          {
            loadingDp ? <Skeleton width={100} height={50} /> :
              <Typography
                variant='h5'
                className={classes.estimatedValue}
              >
                {dp?.Deperd ?? 'N/A'} W
              </Typography>
          }
        </Grid>
      </Grid>
    }
  </Grid>
), styles);
