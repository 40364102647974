import React from 'react';
import Typography from '@mui/material/Typography';
import {
  Checkbox,
  TableCell as MuiTableCell,
  TableFooter,
  Tooltip,
  TableRow,
  TableHead,
  TableBody,
  Table,
  IconButton
} from '@mui/material';
import { Add, FileCopy } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import { genComponent, RenderMenu } from './components';
import { shallowEqual } from 'react-redux';

export const TableCell = withStyles(MuiTableCell, {
  root: {
    borderBottom: 'none',
    '&:first-child': {
      paddingLeft: 0
    }
  },
  head: {
    '&:first-child': {
      paddingLeft: 0
    }
  }
});

const EditableList = ({
  title = '',
  headers = [],
  withHeader,
  data = [],
  setData,
  dataType,
  SubRow,
  withExpansion,
  customHeader,
  canCopy = false,
  selectedCopies = [],
  handleCopyCheck = (e) => e,
  copyCheck = () => false,
  handleSelectedCopies = (e) => e,
}) => {
  const colSpan = headers.length + 1;

  const setNewData = (i, k, v, replace = false) => {
    const nData = [...data].map((e) => {
      if (Object.hasOwn(e, '')) {
        delete e[''];
      }
      return e;
    });
    nData[i] = replace ? { ...nData[i], ...v } : { ...nData[i], [k]: v };
    setData(nData);
  };

  const move = (itemIndex, up) => {
    const nData = [...data].map((e) => {
      if (Object.hasOwn(e, '')) {
        delete e[''];
      }
      return e;
    });
    if (Object.hasOwn(nData[itemIndex], '')) {
      delete nData[itemIndex][''];
    }
    nData.move(itemIndex, up ? itemIndex - 1 : itemIndex + 1);
    setData(nData);
  };

  const remove = (itemIndex) => {
    const nData = [...data];
    nData.splice(itemIndex, 1);
    setData(nData);
  };

  const addNewElement = () => {
    let newElem = headers.reduce((a, b) => ({
      ...a,
      [b.key]: 'defaultValue' in b ? b.defaultValue : ''
    }), {});
    setData([...data, newElem]);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <MuiTableCell variant="head" colSpan={colSpan}>
            {customHeader || (
              <Typography children={title || ''} variant="subtitle2" />
            )}
          </MuiTableCell>
          {canCopy ? (
            <MuiTableCell style={{ width: 15 }}>
              <Tooltip
                disableInteractive
                title={`Copier ${
                  selectedCopies.length ? 'la selection' : 'tout'
                }`}
              >
                <IconButton
                  size="large"
                  children={<FileCopy />}
                  onClick={handleSelectedCopies}
                />
              </Tooltip>
            </MuiTableCell>
          ) : (
            ''
          )}
          <MuiTableCell style={{ width: 15 }}>
            <IconButton
              size="large"
              children={<Add />}
              onClick={addNewElement}
            />
          </MuiTableCell>
        </TableRow>
        {withHeader ? (
          <TableRow>
            {headers.map((h, i) => (
              <TableCell
                key={`th-${i}`}
                variant="head"
                children={h.title || h.key}
                {...(h.props || {})}
              />
            ))}
          </TableRow>
        ) : (
          ''
        )}
      </TableHead>
      <TableBody>
        {data.map((elem, i) => (
          <>
            <TableRow key={`tr-${i}`}>
              {headers.map((h, hi) => {
                const {
                  label,
                  placeholder,
                  type,
                  key,
                  cellProps = {},
                  hide = false,
                  ...p
                } = h || {};
                return (
                  <TableCell
                    key={`td-${hi}`}
                    {...cellProps}
                    {...(hide ? { style: { display: 'none' } } : {})}
                    children={genComponent({
                      index: i,
                      allValues: elem,
                      value: elem[key] || '',
                      label: label,
                      placeholder: placeholder,
                      type: type,
                      hide: hide,
                      setData: (v, r) => setNewData(i, key, v, r),
                      ...p,
                    })}
                  />
                );
              })}
              <TableCell style={{ width: 15 }}>
                <RenderMenu
                  index={i}
                  move={move}
                  remove={remove}
                  last={i === data.length - 1}
                />
              </TableCell>
              {canCopy ? (
                <TableCell style={{ width: 15 }}>
                  <Checkbox
                    checked={copyCheck(elem)}
                    onChange={() => handleCopyCheck(elem)}
                  />
                </TableCell>
              ) : (
                ''
              )}
            </TableRow>
            {(withExpansion ? SubRow && elem.open : SubRow) ? <TableRow
              children={
                <td colSpan={colSpan}
                  children={
                    <SubRow
                      elem={elem}
                      index={i}
                      SCatField={data}
                      setData={setData}
                      dataType={dataType}
                    />
                  }
                />
              }
            /> : ''}
          </>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Typography
              onClick={addNewElement}
              children="Ajouter un élément"
              variant="caption"
              component="p"
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

function areEqual(prevProps, nextProps) {
  return shallowEqual(prevProps.data, nextProps.data) &&
    shallowEqual(prevProps.selectedCopies, nextProps.selectedCopies);
}

export default React.memo(EditableList, areEqual);
