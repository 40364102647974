import React from 'react';
import { Typography } from '@mui/material';

const columns = [
  { title: 'Titre',
    field: 'title',
    render: ({ title }) => <Typography>{title}</Typography>
  },
]

export default columns;
