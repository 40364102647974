import React, {
  Fragment,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Button,
  Typography,
  Grid,
  Divider
} from '@mui/material';
import atlanticQuestion from
  '@lba-dev/package.local-globals/atlanticQuestion';
import SectionPrensenter from './SectionPresenter';
import ShowMore from '../ShowMore';
import notifSystem from '../../../../notifSystem';
import { sortedGroupedValueByKey } from '../../../../utils/sortedHelpers';

const tempHide = true;
const methodCalculQuestion = atlanticQuestion.deperditionData[0];
const isolationQuestion = atlanticQuestion.deperditionData[0].cond[
  methodCalculQuestion.options[3]
].at(1);
const occRegexQuestion = /[1-9]-[a-z]/;

/**
 * @param {{
 * element: {
 * questions: QuestionMetier[]
 * },
 * onValidate: () => void,
 * handleAtlanticSimulation: async (answers: any) => void
 * } & import('react').PropsWithChildren} props
 */
function StepsPrensenter(props) {
  const [activeStep, setActiveStep] = useState(0);
  /**
   * @type {Record<string | undefined, QuestionMetier[]>}
   */
  const pagesGrouped = props.element.questions.groupBy('page');

  const pages = useMemo(
    () => Object.keys(pagesGrouped)
      .filter(e => e !== 'undefined')
      .sort()
    , [pagesGrouped]
  );


  const isStepCompleted = () => pagesGrouped[pages[activeStep]]
    .every(q => {
      if (q.question.startsWith('Z2:')) {
        return true;
      }
      const answers = Object.keys(props.answers.toJS());
      const answer = answers.find(a => a.includes(q.question));
      const match = answer?.match(occRegexQuestion);
      if (!match) {
        const result = !!props.answers.getIn([q.question]);
        if (
          q.question === methodCalculQuestion.label
        ) {
          if (!result) {
            return false;
          }
          return q.responses.every(r => {
            if (r.response === props.answers.getIn([q.question])) {
              return r.subQuestions.every(
                sQ => {
                  const subQuestionAnswer = answers
                    .find(a => a.includes(sQ.question));
                  const matchOcc = subQuestionAnswer?.match(occRegexQuestion);
                  if (!matchOcc) {
                    return !!props.answers.getIn([sQ.question]);
                  }
                  return !!props.answers.getIn([subQuestionAnswer]);
                }
              );
            }
            return true;
          });
        }
        if (
          q.question === isolationQuestion.label
        ) {
          if (!result) {
            return false;
          }
          if (props.answers.getIn([q.question]) ===
            isolationQuestion.options.at(1)) {
            return true;
          }
          return q.responses.every(r => {
            if (r.response === props.answers.getIn([q.question])) {
              return r.subQuestions.every(
                sQ => {
                  const subQuestionAnswer = answers
                    .find(a => a.includes(sQ.question));
                  return !!props.answers.getIn([subQuestionAnswer]);
                }
              );
            }
            return true;
          });
        }

        if (!q.responses.length) {
          return result;
        }
        return q.responses.every(r => {
          if (r.response === props.answers.getIn([answer])) {
            return r.subQuestions.every(
              sQ => {
                const subQuestionAnswer = answers
                  .find(a => a.includes(sQ.question));
                return !!props.answers.getIn([subQuestionAnswer]);
              }
            );
          }
          return true;
        });
      }

      if (!q.responses.length) {
        return !!props.answers.getIn([answer]);
      }
      return q.responses.every(r => {
        if (r.response === props.answers.getIn([answer])) {
          return r.subQuestions.every(
            sQ => {
              const subQuestionAnswer = answers
                .find(a => a.includes(sQ.question));
              return !!props.answers.getIn([subQuestionAnswer]);
            }
          );
        }
        return true;
      });
    }
    );

  const handleNext = useCallback(() => {
    if (!isStepCompleted()) {
      notifSystem.error(
        'Questions non complétées',
        'Veuillez remplir toutes les questions.'
      );
      return;
    }
    if (
      !props.hasAddress &&
      props.answers.getIn([methodCalculQuestion.label]) ===
      methodCalculQuestion.options.at(3)
    ) {
      notifSystem.error(
        'Adresse non complétée',
        'Veuillez renseigner une adresse.'
      );
      const element = document.querySelector('#adressClientDevis');
      element?.focus();
      return;
    }
    if (
      props.answers.hasIn([methodCalculQuestion.label]) &&
      props.answers.getIn([methodCalculQuestion.label]) ===
      methodCalculQuestion.options.at(3) &&
      activeStep === 0
    ) {
      props.handleAtlanticSimulation(props.answers.toJS());
    }
    if (activeStep === pages.length - 1) {
      props.onValidate();
      return;
    }
    setActiveStep(s => s + 1);
  }, [activeStep, isStepCompleted, pages.length, props.onValidate]);

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(s => s - 1);
  }, [activeStep]);

  const getSortedSectionGrouped = useCallback((data) => sortedGroupedValueByKey(
    data.groupBy('section'),
    'sectionPosition'
  ), []);


  if (pages.length === 0) {
    return <SectionPrensenter
      tempHide={tempHide}
      sectionGrouped={{}}
      {...props}
    />;
  }

  return <Box sx={{ width: '100%' }}>
    {
      Object.keys(props.element.questions.groupBy('section')).length &&
      <Typography
        sx={{ mb: 2 }}
        variant="h6"
        children="Questions pour le client"
      />
    }
    <Stepper activeStep={activeStep}>
      {pages.map((page, pageIndex) => (
        <Step key={`step-${pageIndex}`}>
          <StepLabel>Page {page}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <Fragment>
      <SectionPrensenter
        tempHide={tempHide}
        sectionGrouped={
          getSortedSectionGrouped(pagesGrouped[pages[activeStep]])
        }
        {...props}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant='outlined'
          color='inherit'
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Retour
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant='contained'
          color='success'
          onClick={handleNext}
        >
          {
            activeStep === pages.length - 1 ?
              'Valider les informations' : 'Suivant'
          }
        </Button>
      </Box>
    </Fragment>
    <Divider sx={{ my: 2 }} />
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Typography
          variant="h6"
          component="h5"
          children="Discours commercial"
        />
      </Grid>
      {!tempHide && <Grid item xs={4}>
        <ShowMore
          element={{ audio: props.element.audio }}
          text={props.element.mailText}
          title="Discours commercial"
        />
      </Grid>}
      <Grid item xs={10}>
        <Typography children={props.element.mailText} />
      </Grid>
    </Grid>
  </Box>;
}

export default memo(StepsPrensenter);
