import React, { Component } from 'react';
import { List } from 'immutable';

/** STRING */

/**
 * 'deaccentize' method removes accented and diacritic characters
 * from a string and returns the modified string.
 *
 * @returns {String}
 */
String.prototype.deaccentize = function () {
  return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * capitalize is a method useful for displaying a word properly
 *
 * @returns {String}
 */
String.prototype.capitalize = function () {
  return `${this.charAt(0).toUpperCase()}${this.slice(1).toLowerCase()}`;
};

/**
 * toFirstUpperCase upper-cases only the first letter of a string
 *
 * @returns {String}
 */
String.prototype.toFirstUpperCase = function () {
  return `${this.charAt(0).toUpperCase()}${this.slice(1)}`;
};

/**
 * reactify parses a string and turns it into an array of
 * strings and React elements considering the handled characters
 *
 * @returns {String}
 */
String.prototype.reactify = function () {
  const splitted = this.split('\n');

  return splitted.length === 1
    ? this
    : splitted
      .reduce((a, v, i) => {
        a.push(v);
        if (i !== splitted.length - 1) {
          a.push(<br />);
        }
        return a;
      }, []);
};

/**
 * escape regex expression
 *
 * @returns {String}
 */

String.prototype.escapeRegExp = function () {
  return this.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * escape regex expression
 *
 * @returns {String}
 */
String.prototype.parse = function () {
  try {
    return JSON.parse(this);
  } catch (e) {
    return this;
  }
};

/** ARRAY */

/**
 * groupBy is a method useful for creating an object from an
 * array while grouping around a key.
 *
 * @param {String} key - Grouping key
 * @returns {Object}
 */
Array.prototype.groupBy = function (...keys) {
  if (keys.length === 0) {
    throw new Error('At least one key must be provided for grouping.');
  }

  /**
   * @param {any[]} array
   * @param {any[]} remainingKeys
   */
  const groupRecursive = (array, remainingKeys) => {
    const [currentKey, ...nextKeys] = remainingKeys;

    return array.reduce((grouped, item) => {
      const keyValue = item[currentKey];

      // TODO: Add support for empty keys
      // if (!keyValue) {
      //   grouped[0] = this;
      //   return grouped;
      // }

      if (!grouped[keyValue]) {
        grouped[keyValue] = nextKeys.length > 0 ? {} : [];
      }

      if (nextKeys.length > 0) {
        grouped[keyValue] = groupRecursive(
          grouped[keyValue] instanceof Array ? grouped[keyValue] : array,
          nextKeys
        );
      } else {
        grouped[keyValue].push(item);
      }

      return grouped;
    }, {});
  };

  return groupRecursive(this, keys);
};

/**
 * Sort an array of objects by value in key
 *
 * @param {String} key
 * @param {Boolean} ascending - Ascending or descending sort
 * @returns {any[]}
 */
Array.prototype.sortBy = function (key, ascending = true) {
  return this.sort((a, b) => ascending
    ? a[key] - b[key]
    : b[key] - a[key]
  );
};


/**
 * filteredMap is a merge of a mapping and a filtering on an array
 * with the help of reducing.
 * It has been created to dismiss the use of array.map(...).filter(...)
 * which loops two times while creating two new arrays.
 *
 * @param {Function} mapping - Reformat element
 * @param {Function} [filtering] - Validate the addition of an element
 * @param {Boolean} mapBeforeFilter - Transform the element before validating it
 * @returns {Array}
 */
Array.prototype.filteredMap = function (
  mapping,
  filtering,
  mapBeforeFilter = false
) {
  return this.reduce((a, v, i) => {
    let condition = false;

    if (mapBeforeFilter) {
      v = mapping(v, i);
    }
    if (typeof filtering === 'function') {
      condition = filtering(v, i);
    } else if (typeof filtering !== 'undefined') {
      condition = v !== filtering;
    } else if (v) {
      condition = !!v;
    }
    if (condition) {
      a.push(mapBeforeFilter ? v : mapping(v, i));
    }
    return a;
  }, []);
};

/**
 * toggleValue pushes or splices an array considering the given value
 * is already included in this array
 *
 * @param {any} value - Value to compare
 * @param {[Function]} finder - The comparison method
 * @param {[Boolean]} mutate - Set to true if you want to mutate this array
 * @returns {any[]?} - In case mutate is true, this method returns undefined
 */
Array.prototype.toggleValue = function (value, finder, mutate = false) {
  const array = mutate ? this : this.slice();
  const index = typeof finder === 'function'
    ? array.findIndex(finder)
    : array.indexOf(value);

  if (~index) {
    array.splice(index, 1);
  } else {
    array.push(value);
  }
  if (!mutate) {
    return array;
  }
};

/**
 * updateOrInsert pushes or replace an array considering the given value
 * is already included in this array
 *
 * @param {any} value - Value to compare
 * @param {[Function]} finder - The comparison method
 * @param {[Boolean]} mutate - Set to true if you want to mutate this array
 * @returns {any[]?} - In case mutate is true, this method returns undefined
 */
Array.prototype.updateOrInsert = function (value, finder, mutate = false) {
  const array = mutate ? this : this.slice();
  const index = typeof finder === 'function'
    ? array.findIndex(finder)
    : array.indexOf(value);
  if (~index) {
    array[index] = value;
  } else {
    array.push(value);
  }
  if (!mutate) {
    return array;
  }
};

/**
 * insert at specific position or pushes considering the given value
 * is already included in this array
 *
 * @param {any} value - Value to compare
 * @param {[Function]} placeAfter - element to find index and insert after
 * @param {[Function]} finder - The comparison method
 * @param {[Boolean]} mutate - Set to true if you want to mutate this array
 * @returns {any[]?} - In case mutate is true, this method returns undefined
 */
Array.prototype.insertAfterOrPush =
  function (value, placeAfter, finder, mutate = false) {
    const array = mutate ? this : this.slice();
    const index = typeof finder === 'function'
      ? array.findIndex(finder)
      : array.indexOf(placeAfter);
    if (~index) {
      array.splice(index + 1, 0, value);
    } else {
      array.push(value);
    }
    if (!mutate) {
      return array;
    }
  };

/**
 * The delete method lets you remove a value in array identified by
 * the finder function or directly by giving the value in arg
 *
 * @param {Function|any} finder - Finding function or value to remove
 * @returns {any[]}
 */
Array.prototype.delete = function (finder, mutate = false) {
  const array = mutate ? this : this.slice();
  const index = typeof finder === 'function'
    ? array.findIndex(finder)
    : array.indexOf(finder);

  if (~index) {
    array.splice(index, 1);
  }
  return array;
};

Array.prototype.asyncForEach = async function (callback) {
  for (let index = 0; index < this.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(this[index], index, this);
  }
};

Array.prototype.chunk = function (size) {
  return Array.from(
    { length: Math.ceil(this.length / size) },
    (v, i) => this.slice(i * size, (i * size) + size)
  );
};

/**
 * change position of item in array
 * @param {Integer} from - item position
 * @param {Integer} to - move to
 */
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

List.prototype.toggleValue = function (value) {
  const index = this.indexOf(value);

  return ~index
    ? this.delete(index)
    : this.push(value);
};

/** COMPONENT */

/**
 * promisifiedSetState is the setState method but returning a promise
 *
 * @param {Object} state
 * @returns {Promise<void>} - The returned promise always resolve
 */
Component.prototype.promisifiedSetState = function (state) {
  return new Promise(res => this.setState(state, res));
};

/**
 * @param {String} key - Key of the wanted array in state
 * @param {[Function]} comparator - Comparing value function
 */
Component.prototype.toggleArray = function (key, comparator) {
  return (value, cb = e => e) => this.setState(state => ({
    [key]: state[key].toggleValue(value, comparator)
  }), cb);
};

Number.prototype.toFlooredFixed = function (number) {
  return (
    Math.floor(this * Math.pow(10, number)) / Math.pow(10, number)
  ).toFixed(number);
};

