import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog } from './dialog';
import { data as TP_Array } from '@lba-dev/package.local-globals/templatesType'

/**
 * send a SMS with template
 * @param {Object} elem
 * @param {Object} selected
 * @returns {Promise}
 */

export const displayTemplates = (demandes) => {
  api.templates.getAll({})
    .then((response) => {
      let data = response.body().map(e => e.data());
      if (Array.isArray(data) && !!data.length) {
        data = data.map((e, i) => ({
          ...e,
          id: i + 1,
        }));
      }
      return store.dispatch(
        setDialog({
          name: 'DialogTemplates',
          open: true,
          dialogProps: {
            maxWidth: 'md',
          },
          contentProps: {
            templates: data,
          },
          actions: [
            {
              children: 'Envoyer',
              color: 'primary',
              hideButton: (data) => data.editMode,
              onClick: (props, close) => {
                const { selectedTemplate } = props;
                if (!selectedTemplate) {
                  notifSystem.error('Erreur',
                    'Veuillez sélectionner un template');
                } else if (JSON.stringify(selectedTemplate) === '{}') {
                  notifSystem.error('Erreur',
                    'Veuillez remplir les champs et ajouter ce template avant');
                } else {
                  api.demandes.custom('relance').post({
                    smsContent: selectedTemplate.text,
                    demandes: demandes.map(e => ({
                      _id: e._id,
                      clientTel: e.client.tel1 || e.client.tel,
                      clientName: `${e.client.name} ${e.client.firstname}`,
                      clientEmail: e.client.email1 || e.client.email,
                    })),
                    mode: TP_Array
                      .find(e => e._id === selectedTemplate.type)
                      .lowercaseName,
                  }).then(() => {
                    close();
                    notifSystem
                      .success('SMS envoyé', 'Le SMS a bien été envoyé');
                  }).catch((error) => {
                    notifSystem
                      .error('Erreur', 'Le SMS n\'a pas pû être envoyé', error);
                  });
                }
              },
            }
          ],
        })
      );
    })
    .catch((error) => {
      notifSystem.error(error);
    });
};
