import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { TP_SMS } from '@lba-dev/package.local-globals/templatesType'

export const deleteTemplate = (oldData, setTemplates, templates) =>
  api.one('templates', oldData._id).delete().then(
    ( ) => {
      notifSystem.success('Template supprimé',
        'Le template a bien été supprimé');
      setTemplates(templates.filter(template => template._id !== oldData._id
      ));
    }).catch((error) => {
    notifSystem.error('Erreur lors de la suppression du template', error);
  })

export const updateTemplate = (
  {
    selectedTemplate,
    setTemplates,
    setDisplayForm,
    templates,
    setEditMode,
  }
) => {
  const { _id, ...updateTemplate } = selectedTemplate;
  const { title, text } = updateTemplate;
  delete updateTemplate.tableData;
  if (!title || !text) {
    notifSystem.error('Erreur lors de la modification du template',
      'Le titre et le contenu du template sont obligatoires');
  } else {
    api.one('templates', _id).patch({
      title,
      text,
    }).then(() => {
      notifSystem.success('Template modifié', 'Le template a bien été modifié');
      setTemplates(templates.map(template =>
        template._id === _id ? { ...template, title, text } : template
      ));
      setEditMode(false);
      setDisplayForm(false);
    }).catch((error) => {
      notifSystem.error('Erreur lors de la modification du template', error);
    });
  }
}

export const handleAddTemplate = (
  {
    newTemplate,
    setTemplates,
    setNewTemplate,
    setDisplayForm,
    templates,
  }) => {
  if (!newTemplate.title || !newTemplate.text) {
    notifSystem.error('Erreur lors de l\'ajout du template',
      'Le titre et le contenu du template sont obligatoires');
  } else {
    api.templates.post({
      ...newTemplate,
    }).then((response) => {
      const addedTemplate = response.body().data();
      notifSystem.success('Nouveau Template', 'Le template a bien été ajouté');
      setTemplates([...templates, addedTemplate]);
      setNewTemplate({
        title: '',
        text: '',
        type: TP_SMS,
      });
      setDisplayForm(false);
    }).catch((error) => {
      notifSystem.error('Erreur lors de l\'ajout du template', error);
    });
  }
}

