import { updateTemplate, handleAddTemplate } from './apiFunctions';

export const handleRowClick = (
  e,
  rowData,
  setSelectedTemplate,
  selectedTemplate
) => {
  setSelectedTemplate(selectedTemplate._id === rowData._id ? {} : rowData);
}

export const returnToTemplates = ({
  setEditMode,
  setSelectedTemplate,
  setTemplates,
  templates,
  setDisplayForm,
  displayForm
}
) => {
  setEditMode(false);
  setSelectedTemplate({})
  setTemplates(templates);
  setDisplayForm(!displayForm)
}


export const handleTitleChange = ({
  e,
  editMode,
  selectedTemplate,
  setSelectedTemplate,
  newTemplate,
  setNewTemplate
}) => {
  if (editMode) {
    setSelectedTemplate({
      ...selectedTemplate,
      title: e.target.value,
    });
  } else {
    setNewTemplate({
      ...newTemplate,
      title: e.target.value,
    });
  }
}


export const handleTextChange = (
  {
    e,
    editMode,
    selectedTemplate,
    setSelectedTemplate,
    newTemplate,
    setNewTemplate
  }
) => {
  if (editMode) {
    setSelectedTemplate({
      ...selectedTemplate,
      text: e.target.value,
    });
  } else {
    setNewTemplate({
      ...newTemplate,
      text: e.target.value,
    })
  }
}


export const handleTemplate = (
  {
    editMode,
    selectedTemplate,
    newTemplate,
    setTemplates,
    setDisplayForm,
    templates,
    setNewTemplate,
    setEditMode,
  }
) => {
  if (editMode) {
    updateTemplate(
      {
        selectedTemplate,
        setTemplates,
        setDisplayForm,
        templates,
        setEditMode,
      }
    );
  } else {
    handleAddTemplate(
      {
        newTemplate,
        setTemplates,
        setNewTemplate,
        setDisplayForm,
        templates,
        setEditMode
      }
    );
  }
}

