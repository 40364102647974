import React, { PureComponent } from 'react';
import Notification from './Notification';
import notifSystem from '../../notifSystem';

class Notifications extends PureComponent {
  waitNotif = false;
  state = {
    notifs: [],
  };
  componentDidMount() {
    this.unsubscribe = notifSystem.subscribe(this.incomingNotif);
    this.readNotif();
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  incomingNotif = () => {
    if (this.waitNotif) {
      this.readNotif();
    }
  };

  removeNotif = id => {
    this.setState(
      (prevState) => ({
        notifs: prevState.notifs.filter((e) => e.id !== id),
      }),
      this.readNotif
    );
  }

  readNotif = () => {
    this.waitNotif = false;
    this.setState((prevState) => {
      let notifs = prevState.notifs;
      let hasNotif = true;
      while (notifs.length < 5 && hasNotif) {
        const notif = notifSystem.consume();
        if (notif !== undefined) {
          notifs = [...notifs, notif];
        } else {
          hasNotif = false;
        }
      }
      if (hasNotif === false) {
        this.waitNotif = true;
      }
      return { notifs };
    });
  }

  render() {
    const { notifs } = this.state;
    const isConv = window.location.pathname === '/conversations';
    if (isConv) {
      return <></>;
    }

    return (
      <>
        <div
          style={{
            position: 'absolute',
            bottom: 50,
            left: 32,
            zIndex: 1301
          }}
        >
          {notifs
            .filter((e) => !['sms', 'email', 'popUp'].includes(e.type.name))
            .map((e) => (
              <Notification
                message={e}
                key={e.id}
                removeNotif={this.removeNotif}
              />
            ))}
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 50,
            right: 70,
            zIndex: 1500
          }}
        >
          {notifs
            .filter((e) => ['sms', 'email', 'popUp'].includes(e.type.name))
            .map((e) => (
              <Notification
                message={e}
                key={e.id}
                removeNotif={this.removeNotif}
              />
            ))}
        </div>
      </>
    );
  }
}

export default Notifications;
